import {TrinityEvents} from "../modules/teaser/trinity.js"
import JshModule from '@core/scripts/helper/jsh-module';

const {config} = JshModule('teaser/flowbox-teaser');

function waitFor(condition, timeout) {
  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (condition()) {
        clearInterval(interval);
        resolve();

      }
    }, timeout)
  })
}

function init() {
  (function (d, id) {
    if (!window.flowbox) {
      var f = function () {
        f.q.push(arguments);
      };
      f.q = [];
      window.flowbox = f;
    }
    if (d.getElementById(id)) {
      return;
    }
    var s = d.createElement('script'), fjs = d.scripts[d.scripts.length - 1];
    s.id = id;
    s.async = true;
    s.src = 'https://connect.getflowbox.com/flowbox.js';
    fjs.parentNode.insertBefore(s, fjs);
  })(document, 'flowbox-js-embed');

  window.flowbox('init', {
    container: `#flowbox_content_${config.entryId}`,
    key: config.flowboxKey,
    locale: config.locale,
    productId: config.productId,
    tags: config.tags,
    allowCookies: false
  });


  const observer = new IntersectionObserver((entries) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        observer.disconnect();
        await triggerPostprocessing(postProcessor);
      }
    })
  })
  observer.observe(document.querySelector(`#flowbox_content_${config.entryId}`));
}

async function triggerPostprocessing(postProcessor) {
  await waitFor(() => {
    const flowboxDiv = document.querySelector(`#flowbox_content_${config.entryId}`);
    return flowboxDiv && flowboxDiv.children.length > 0;
  }, 1000)
  postProcessor?.()
}

function postProcessor() {
  const contentList = document.getElementsByClassName('content-list');
  const pageName = contentList[0].getAttribute(`jsh-tracking__trinityClickPage`);

  const flowboxDiv = document.querySelector(`#flowbox_content_${config.entryId}`);
  const children = flowboxDiv.querySelectorAll('.bzfy-o-layout__item');
  TrinityEvents.sendFlowboxTeaserView({
    numberOfPicture: children.length,
    page: pageName
  });
  children.forEach((element, index) => {
    element.onclick = async () => {
      TrinityEvents.sendFlowboxTeaserClick({page: pageName});

      await waitFor(() => document.querySelector('#bzfy-modal-wrapper'), 1000)
      const modal = document.querySelector('#bzfy-modal-wrapper');
      modal.onclick = (e) => {
        if (e.target.closest('div.bzfy-gate-product')) {
          const querySelector = e.target.closest('div.bzfy-gate-product').querySelector('a.bzfy-gate-product-buy-button');
          const itemNumberMatch = querySelector?.href?.match(/.*\/(?:[a-z0-9\-]+-)?([0-9]{6,9})/);
          if (itemNumberMatch) {
            const itemNumber = parseInt(itemNumberMatch[1], 10);
            TrinityEvents.sendFlowboxTeaserLayerClick({articleNumber: itemNumber, page: pageName});
          }
        }
      }
    }
  })
}


init();
